<template>
  <div id="see">
    <a-page-header
      title="查看操作日志"
      style="padding-left: 0; margin-bottom: 10px; border-bottom: 1px solid rgb(235, 237, 240)"
    />
    <a-row>
      <a-form-model :model="form">
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="角色">
          <p>
            {{ form.roleName }}
          </p>
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="账户">
          <p>{{ form.userNo }}</p>
        </a-form-model-item>

        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="模块">
          <p>{{ form.module }}</p>
        </a-form-model-item>

        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="操作类型">
          <p>
            {{ form.operate }}
          </p>
        </a-form-model-item>

        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="操作描述">
          <p>{{ form.introduce }}</p>
        </a-form-model-item>

        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="操作参数">
          <p>{{ form.operateParameter }}</p>
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="操作时间">
          <p>{{ form.gmtModified }}</p>
        </a-form-model-item>

        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="IP">
          <p>{{ form.ip }}</p>
        </a-form-model-item>

        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="等级">
          <p>{{ form.levels }}</p>
        </a-form-model-item>
      </a-form-model>
    </a-row>
    <div class="fotter">
      <a-button key="back" @click="handleCancel"> 返回 </a-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelCol: { span: 2},
      wrapperCol: { span: 22 },
      form: {},
    };
  },
  mounted() {
    this.form = JSON.parse(this.$route.query.record);
    console.log(this.form);
  },
  methods: {
    //取消
    handleCancel() {
      this.$router.push({ path: "/basicInformation/journal" });
    },
  },
};
</script>

<style scoped>
.fotter {
  display: flex;
  justify-content: center;
}

#see p {
  font-weight: bolder;
  margin-left: 10px;
  /* font-family: "楷体"; */
}
</style>
